// import React, { useState } from 'react';
// import AWS from 'aws-sdk';
// import './FileUpload.css';

// const App = () => {
//   const [file, setFile] = useState(null);
//   const [uploading, setUploading] = useState(false);
//   const [progress, setProgress] = useState(0);
//   const [dragging, setDragging] = useState(false);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//   };

//   const handleDragOver = (event) => {
//     event.preventDefault();
//     setDragging(true);
//   };

//   const handleDragLeave = () => {
//     setDragging(false);
//   };

//   const handleDrop = (event) => {
//     event.preventDefault();
//     setDragging(false);
//     setFile(event.dataTransfer.files[0]);
//   };

//   const uploadFile = () => {
//     if (!file) return alert("Please select a file to upload.");

//     setUploading(true);

//     const s3 = new AWS.S3({
//       accessKeyId: 'AKIAXYKJWKKMP33E5KUS',
//       secretAccessKey: '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',
//       region: 'ap-south-1',
//     });

//     const params = {
//       Bucket: 'nayka',
//       Key: file.name,
//       Body: file,
//       ContentType: file.type,
//     };

//     const options = {
//       partSize: 5 * 1024 * 1024,
//       queueSize: 10,
//     };

//     s3.upload(params, options)
//       .on('httpUploadProgress', (evt) => {
//         setProgress(Math.round((evt.loaded / evt.total) * 100));
//       })
//       .send((err, data) => {
//         setUploading(false);
//         if (err) {
//           console.error("Error uploading file: ", err);
//         } else {
//           alert("File uploaded successfully!");
//         }
//       });
//   };

//   return (
//     <div className="file-upload-container">
//       <div
//         className={`file-drop-zone ${dragging ? 'dragging' : ''}`}
//         onDragOver={handleDragOver}
//         onDragLeave={handleDragLeave}
//         onDrop={handleDrop}
//       >
//         <input
//           type="file"
//           id="fileInput"
//           className="file-input"
//           onChange={handleFileChange}
//         />
//         <label htmlFor="fileInput" className="file-label">
//           {file ? file.name : 'Drag and drop a file or click to select'}
//         </label>
//       </div>
//       <button onClick={uploadFile} disabled={!file || uploading} className="upload-button">
//         {uploading ? `Uploading ${progress}%` : 'Upload'}
//       </button>
//       {uploading && <progress value={progress} max="100" className="progress-bar">{progress}%</progress>}
//     </div>
//   );
// };

// export default App;

import React from 'react';
import './App.css';
import S3Uploader from './FileUpload';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Nykaa Multiple File Uploader</h1>
      </header>
      <S3Uploader />
    </div>
  );
}

export default App;

