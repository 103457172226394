import React, { useState } from 'react';
import AWS from 'aws-sdk';
import './FileUpload.css';

const S3Uploader = () => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState({});

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(Array.from(event.dataTransfer.files));
  };

  const getFolderByFileType = (fileType) => {
    if (fileType.startsWith('image/')) {
      return 'images/';
    } else if (fileType.startsWith('video/')) {
      return 'videos/';
    } else if (fileType.startsWith('audio/')) {
      return 'audio/';
    } else {
      return 'others/';
    }
  };

  const uploadFile = (file) => {
    const s3 = new AWS.S3({
      accessKeyId: 'AKIAXYKJWKKMP33E5KUS',
      secretAccessKey: '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',
      region: 'ap-south-1',
    });

    const folder = getFolderByFileType(file.type);
    const params = {
      Bucket: 'nayka',
      Key: `${folder}${file.name}`,
      Body: file,
      ContentType: file.type,
    };

    const options = {
      partSize: 5 * 1024 * 1024,
      queueSize: 10,
    };

    return new Promise((resolve, reject) => {
      s3.upload(params, options)
        .on('httpUploadProgress', (evt) => {
          setProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: Math.round((evt.loaded / evt.total) * 100),
          }));
        })
        .send((err, data) => {
          if (err) {
            console.error("Error uploading file: ", err);
            reject(err);
          } else {
            resolve(data);
          }
        });
    });
  };

  const uploadFiles = () => {
    if (!files.length) return alert("Please select files to upload.");

    setUploading(true);

    const uploadPromises = files.map((file) => uploadFile(file));

    Promise.all(uploadPromises)
      .then(() => {
        alert("Files uploaded successfully!");
        setUploading(false);
        setFiles([]);
      })
      .catch((err) => {
        console.error("Error uploading files: ", err);
        setUploading(false);
      });
  };

  return (
    <div className="file-upload-container">
      <div
        className="file-drop-zone"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="fileInput"
          className="file-input"
          multiple
          onChange={handleFileChange}
        />
        <label htmlFor="fileInput" className="file-label">
          {files.length ? `${files.length} file(s) selected` : 'Drag and Drop Files or Click to Upload'}
        </label>
      </div>
      <button onClick={uploadFiles} disabled={!files.length || uploading} className="upload-button">
        {uploading ? 'Uploading...' : 'Upload'}
      </button>
      {uploading && (
        <div className="progress-container">
          {files.map((file) => (
            <div key={file.name} className="progress-item">
              <span>{file.name}</span>
              <progress value={progress[file.name] || 0} max="100">{progress[file.name] || 0}%</progress>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default S3Uploader;